<template>
    <Modal id="terms-and-conditions-modal"
           size="xl"
           :centered="true"
           :show="show"
           v-on="$listeners"
    >
        <div v-if="termsAndConditions" class="pt-6 px-sm-4 px-md-6 text-justify">
            <h2 class="h1 font-weight-semibold position-relative mb-5 text-center"
                v-html="termsAndConditions.title.rendered">
            </h2>
            <div class="text-justify"
                 v-html="termsAndConditions.content.rendered">
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import {mapGetters} from "vuex";

export default {
    name: "TermsAndConditionsModal",
    components: {Modal},
    props: {
        show: {
            default: false,
            type: Boolean,
        }
    },
    computed: {
        ...mapGetters({
            termsAndConditions: 'terms/termsAndConditions'
        })
    }
}
</script>

<style scoped>

</style>