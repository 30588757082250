<template>
    <Modal id="privacy-policy-modal"
           size="lg"
           :centered="true"
           :show="show"
           v-on="$listeners"
    >
        <div v-if="privacyPolicy" class="pt-6 px-4 px-md-6">
            <h2 class="h1 font-weight-semibold position-relative mb-5 text-center"
                v-html="privacyPolicy.title.rendered">
            </h2>
            <div class="text-justify"
                 v-html="privacyPolicy.content.rendered">
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import {mapGetters} from "vuex";

export default {
    name: "PrivacyPolicyModal",
    components: {Modal},
    props: {
        show: {
            default: false,
            type: Boolean,
        }
    },
    computed: {
        ...mapGetters({
            privacyPolicy: 'terms/privacyPolicy'
        })
    }
}
</script>

<style scoped>

</style>