<template>
    <PublicLayout v-if="loaded" id="app-auth">
        <Logo class="align-items-center my-2 my-md-5 my-lg-7"/>
        <RegisterForm />
        <CertifiedLogos class="certified-logos my-5 py-5 flex-column"/>
    </PublicLayout>
</template>

<script>
import RegisterForm from "@/shared/authentication/RegisterForm";
import Logo from "@/components/Logo";
import CertifiedLogos from "@/components/CertifiedLogos";
import {name} from "@/config/app";

export default {
    name: "Register",
    middleware: 'guest',
    meta: {
        title: `Register - ${name}`
    },
    data: () => ({
        loaded: false,
    }),
    async beforeCreate() {
        await this.$store.dispatch('units/fetchCurrencies');
        this.loaded = true;
    },
    components: {CertifiedLogos, Logo, RegisterForm},
}
</script>

<style lang="scss">
#app-auth {
    .navbar-brand {
        svg {
            width: 250px;
            height: auto;

            @include media-breakpoint-down('sm') {
                width: 200px;
            }
        }
    }

    .certified-logos {
        margin-bottom: 1rem;

        strong {
            margin-bottom: 1rem;
        }

        img {
            &:first-child {
                margin-left: 0 !important;
            }
        }
    }
}
</style>