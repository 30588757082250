<template>
    <div class="card col-md-10 col-xl-8 mx-auto px-0">
        <div class="card-body">
            <h4 class="mt-5 mb-4 lead font-weight-medium">Sign Up</h4>
            <h5 class="font-weight-light mb-3">Don't have an account? Create your account, it takes less than a
                minute.</h5>
            <form id="register-form" class="pt-3 pb-xl2-4" @submit.prevent="handleSubmit">
                <VueRecaptcha :sitekey="sitekey"
                              size="invisible"
                              :loadRecaptchaScript="true"
                              @verify="register"
                              @expired="resetRecaptcha"/>
                <div class="row">
                    <div class="col-md-6">
                        <Input class-name="form-control-lg"
                               name="register_first_name"
                               label="First Name"
                               placeholder="Enter your first name"
                               @change="handleChange"
                               :value="register_first_name"
                               :v="$vServer.register_first_name || $v.register_first_name"/>
                    </div>
                    <div class="col-md-6">
                        <Input class="mt-4 mt-md-0"
                               class-name="form-control-lg"
                               name="register_last_name"
                               label="Last Name"
                               placeholder="Enter your last name"
                               @change="handleChange"
                               :value="register_last_name"
                               :v="$vServer.register_last_name || $v.register_last_name"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <Input class="mt-4"
                               class-name="form-control-lg"
                               name="register_email"
                               type="email"
                               label="Email address"
                               placeholder="Enter your email"
                               autocomplete="username"
                               @change="handleChange"
                               :value="register_email"
                               :v="$vServer.register_email || $v.register_email"/>
                    </div>
                    <div class="col-md-6 mt-4">
                        <InputPhone class-name="form-control-lg"
                                    name="register_phone_number"
                                    label="Phone number"
                                    v-on="$listeners"
                                    :v="$vServer.register_phone_number || $v.register_phone_number"
                                    @change="handleChange"/>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <Input class="mt-4"
                               class-name="form-control-lg"
                               name="register_password"
                               type="password"
                               label="Password"
                               placeholder="Enter your password (6 or more characters)"
                               autocomplete="new-password"
                               @change="handleChange"
                               :value="register_password"
                               :v="$vServer.register_password || $v.register_password"/>
                    </div>
                    <div class="col-md-6">
                        <Input class="mt-4"
                               class-name="form-control-lg"
                               name="register_password_confirmation"
                               type="password"
                               label="Confirm password"
                               placeholder="Confirm password"
                               autocomplete="new-password"
                               @change="handleChange"
                               :value="register_password_confirmation"
                               :v="$vServer.register_password_confirmation || $v.register_password_confirmation"/>
                    </div>
                </div>

                <hr class="mt-5">

                <div class="row">
                    <div class="col-md-6">
                        <Input class-name="form-control-lg"
                               name="register_company"
                               label="Company"
                               placeholder="Enter your company name"
                               @change="handleChange"
                               :value="register_company"
                               :v="$vServer.register_company || $v.register_company"/>
                    </div>
                    <div class="col-md-6">
                        <Input class="mt-4 mt-md-0"
                               class-name="form-control-lg"
                               name="register_vat_number"
                               label="Vat Number / Tax Id Number"
                               placeholder="Enter your vat number"
                               @change="handleChange"
                               :value="register_vat_number"
                               :v="$vServer.register_vat_number || $v.register_vat_number"/>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <Input class="mt-4"
                               class-name="form-control-lg"
                               name="register_address1"
                               label="Address"
                               placeholder="Enter your address"
                               @change="handleChange"
                               :value="register_address1"
                               :v="$vServer.register_address1 || $v.register_address1"/>
                    </div>
                    <div class="col-md-6">
                        <Input class="mt-4"
                               class-name="form-control-lg"
                               name="register_city"
                               label="City"
                               placeholder="Enter your city"
                               @change="handleChange"
                               :value="register_city"
                               :v="$vServer.register_city || $v.register_city"/>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-3">
                        <Input class="mt-4"
                               class-name="form-control-lg"
                               name="register_post_code"
                               label="Postcode"
                               placeholder="Enter your postcode"
                               @change="handleChange"
                               :value="register_post_code"
                               :v="$vServer.register_post_code || $v.register_post_code"/>
                    </div>
                    <div class="col-md-5">
                        <Input class="mt-4"
                               class-name="form-control-lg"
                               name="register_country"
                               label="Country"
                               placeholder="Enter your country"
                               @change="handleChange"
                               :value="register_country"
                               :v="$vServer.register_country || $v.register_country"/>
                    </div>
                    <div class="col-md-4">
                        <Select class="mt-4"
                                class-name="form-control-lg"
                                name="register_currency"
                                label="Currency"
                                @change="handleChange"
                                :value="register_currency"
                                :options="currenciesCast"
                                :v="$vServer.register_currency || $v.register_currency"/>
                    </div>
                </div>

                <div class="mt-6 d-flex flex-column flex-lg-row justify-content-between align-items-lg-center">
                    <Checkbox class="mb-4 mb-lg-2"
                              name="register_terms_and_conditions"
                              :value="1"
                              :checked="register_terms_and_conditions > 0"
                              @change="handleChange"
                              :v="$vServer.register_terms_and_conditions || $v.register_terms_and_conditions"
                    >
                        <template slot="afterLabel">

                            I accept the <a href="#" @click.prevent="showPrivacyPolicy">privacy policy</a> and the
                            <a href="#" @click.prevent="showTermsAndConditions">terms and conditions</a> <br>of the Gepax Platform website.
                        </template>
                    </Checkbox>
                    <button class="btn btn-success btn-lg py-2 px-5">Sign Up</button>
                </div>
            </form>
            <hr class="my-5">
            <p class="text-center">
                Already have an account?
                <router-link :to="{name: 'login'}">Log In</router-link>
            </p>
        </div>

        <PrivacyPolicyModal :show="showModalPrivacyPolicy" @hideModal="showModalPrivacyPolicy = false"/>
        <TermsAndConditionsModal :show="showModalTermsAndConditions" @hideModal="showModalTermsAndConditions = false"/>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Input from "@/components/form/Input";
import VueRecaptcha from 'vue-recaptcha';
import {scrollToError} from "@/helpers";
import {REGISTER_URL} from "@/config/api";
import {email, minLength, required, sameAs} from "vuelidate/lib/validators";
import {sitekey} from "@/config/recaptcha";
import {guest_route} from "@/config/auth";
import Checkbox from "@/components/form/Checkbox";
import InputPhone from "@/components/form/InputPhone";
import Select from "@/components/form/Select";
import PrivacyPolicyModal from "@/shared/authentication/PrivacyPolicyModal";
import TermsAndConditionsModal from "@/shared/authentication/TermsAndConditionsModal";

export default {
    name: "RegisterForm",
    components: {TermsAndConditionsModal, Select, InputPhone, Checkbox, Input, VueRecaptcha, PrivacyPolicyModal},
    data: () => ({
        showModalPrivacyPolicy: false,
        showModalTermsAndConditions: false,

        submitted: false,
        register_first_name: null,
        register_last_name: null,
        register_email: null,
        register_phone_number: null,
        register_password: null,
        register_password_confirmation: null,
        register_terms_and_conditions: null,
        register_company: null,
        register_vat_number: null,
        register_address1: null,
        register_post_code: null,
        register_city: null,
        register_country: null,
        register_currency: null,
    }),
    validations: {
        register_first_name: {required},
        register_last_name: {required},
        register_email: {required, email},
        register_phone_number: {required},
        register_password: {
            required,
            minLength: minLength(6),
        },
        register_password_confirmation: {
            required,
            minLength: minLength(6),
            sameAs: sameAs('register_password')
        },
        register_terms_and_conditions: {required},
        register_company: {required},
        register_vat_number: {required},
        register_address1: {required},
        register_post_code: {required},
        register_city: {required},
        register_country: {required},
        register_currency: {required}
    },
    computed: {
        ...mapGetters({
            '$vServer': 'serverNotice/errors',
            'currencies': 'units/currencies',
        }),
        sitekey() {
            return sitekey;
        },
        recaptcha() {
            return this.$children[0];
        },
        formData() {
            const formData = {};
            for (const [key, value] of Object.entries(this.$data))
                if (key.indexOf('register_') > -1)
                    formData[key.replace('register_', '')] = value;

            formData['tax_id_number'] = formData.vat_number;
            return formData;
        },
        currenciesCast() {
            let currencies = {};
            for (const [code, currency] of Object.entries(this.currencies.currencies)) {
                const {name, symbol} = currency;
                currencies[code] = `${symbol} (${code}, ${name})`
            }
            return currencies;
        }
    },
    methods: {
        handleChange({name, value}) {
            if (this[name] !== value) {
                // clear server errors
                this.$store.dispatch('serverNotice/clearErrors');
                this[name] = value;
            }
        },
        async handleSubmit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$nextTick(() => scrollToError(this.$el))
            } else {
                this.recaptcha.execute(); // run recaptcha
            }
        },
        async register() {
            if (this.submitted) return false;

            this.submitted = true;
            this.$store.dispatch('serverNotice/clearNotice');
            try {
                this.$root.$preloader.start();

                const {data, status} = await this.$http.post(REGISTER_URL, this.formData, {
                    timeout: 10000,
                });

                this.$store.dispatch('serverNotice/setNotice', {
                    message: data.message,
                    status: status,
                });

                this.redirect()

            } catch (e) {
                const {data, status} = e.response;
                if (!data.status)
                    data.status = status;

                if (data.request && data.errors) {
                    for (const [name, value] of Object.entries(data.errors)) {
                        data.errors[`${data.request}_${name}`] = value;
                        this[`${data.request}_${name}`] = null;
                        delete data.errors[name];
                    }
                }
                this.$store.dispatch('serverNotice/setNotice', data);
                this.resetForm();
                this.recaptcha.reset();
                this.$root.$preloader.finish();

                this.$nextTick(() => scrollToError(this.$el))
            }

        },
        resetForm() {
            this.register_password = null;
            this.register_password_confirmation = null;
            this.submitted = false;
            this.$v.$reset();
        },
        resetRecaptcha() {
            this.recaptcha.reset();
        },
        redirect() {
            this.$router.push({name: guest_route})
        },

        async showPrivacyPolicy(){
            await this.$store.dispatch('terms/fetchPrivacyPolicy');
            this.showModalPrivacyPolicy = true;
        },

        async showTermsAndConditions(){
            await this.$store.dispatch('terms/fetchTermsAndConditions');
            this.showModalTermsAndConditions = true;
        }

    }
}
</script>
<style lang="scss">
#register-form {
    .form-control-lg {
        @include font-size($input-font-size);
        height: 50px;
        display: flex;
        align-items: center;

        &#register_phone_number {
            height: auto;
            align-items: flex-start;
        }
    }
}
</style>
